.unselect, body, *,
*::after,
*::before {
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  -webkit-app-region: no-drag !important;
  -webkit-touch-callout: none !important;
  -webkit-user-drag: none !important;
  -webkit-user-select: none !important;
  cursor: none !important;
  user-select: none !important;
}

body {
  font-family: "mv-fonts-lato";
  margin: 0;
}
