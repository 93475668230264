body {
    @extend .unselect;
    font-family: "mv-fonts-lato";
    margin: 0;
}

*,
*::after,
*::before {
    @extend .unselect;
}
