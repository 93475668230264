// please remember to change the typescript values if you change these
// packages/moustache-walrus/src/app/models/device/device.ts
$target-height: 3240px;
$target-width: 5760px;

@function getVw($target) {
    $vw-context: ($target-width * 0.01);
    @return ($target / $vw-context) * 1vw;
}

@function getVh($target) {
    $vh-context: ($target-height * 0.01);
    @return ($target / $vh-context) * 1vh;
}

.unselect {
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    -webkit-app-region: no-drag !important;
    -webkit-touch-callout: none !important;
    -webkit-user-drag: none !important;
    -webkit-user-select: none !important;
    cursor: none !important;
    user-select: none !important;
}
